





















































































































































































































import Vue from "vue";
import Footer from "@/components/layout/Footer.vue";

export default Vue.extend({
  name: "About",
  components: { Footer },
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
});
